<template>
  <div class="card card-custom">

    <div class="card card-custom mb-5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-end">
            <button type="button" class="btn btn-primary mr-2" :disabled="is_requesting" @click="getFileTemplate">{{ $t('export_template') }}</button>
          </div>
          <div class="col-md-3 mt-5 mb-10">
            <div :class="{'current_step': (step == 1), 'done_step': (step > 1)}">
              <div class="d-flex">
                <div>
                  <span class="step-circle-1">
                    <span class="step-circle-2">1</span>
                  </span>
                </div>
                <div class="d-flex align-items-center"><h3 class="step-circle-title"> {{ $t('import_options') }} <i :class="angle_class"></i></h3></div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-5 mb-10">
            <div :class="{'current_step': (step == 2), 'done_step': (step > 2)}">
              <div class="d-flex">
                <div>
                  <span class="step-circle-1">
                    <span class="step-circle-2">2</span>
                  </span>
                </div>
                <div class="d-flex align-items-center"><h3 class="step-circle-title"> {{ $t('define_fields') }} <i :class="angle_class"></i></h3></div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-5 mb-10">
            <div :class="{'current_step': (step >= 3), 'done_step': process_finished}">
              <div class="d-flex">
                <div>
                  <span class="step-circle-1">
                    <span class="step-circle-2">3</span>
                  </span>
                </div>
                <div class="d-flex align-items-center"><h3 class="step-circle-title"> {{ $t('results') }} </h3></div>
              </div>
            </div>
          </div>
        </div>


        <div v-show="step == 1" class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-12 mt-5">
              <label>{{ $t('export_import.specified') }}</label>
              <select name="" id="specified" v-model="specified" class="custom-select">
                <option v-for="(row , index) in specified_list" :value="row.key" :key="'specified_'+index">
                  {{ row.title }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-5">
              <label>{{ $t('export_import.import_data') }}</label>
              <div class="form-group">
                <div style="margin-left: 5px;margin-right: 5px;">
                  <input :id="'inputImportExcel'+innerId" type="file" ref="fileField" class="inputfile" v-on:change="uploadFile" :disabled="disableLoadingButton">
                  <label :for="'inputImportExcel'+innerId" :class="btnClass" :disabled="disableLoadingButton">
                    <i class="fa fa-file-import"></i>
                    {{ placeholder }}
                    <span v-if="disableLoadingButton"><i class="fa fa-spinner fa-pulse fa-fw"></i></span>
                  </label>
                </div>

              </div>
            </div>

            <div class="form-group col-md-6 mt-5">
              <b-form-checkbox size="lg" v-model="import_first_row" name="check-button" switch>
                {{ $t('export_import.import_the_first_row_of_the_file') }}
              </b-form-checkbox>
            </div>

            <div class="form-group col-md-12 mt-5">
              <b-alert v-if="alter_message" show variant="danger">{{ alter_message }}</b-alert>
            </div>


          </div>

        </div>

        <div v-show="step == 2" class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-8 mt-5">
              <h5>{{ $t('export_import.select_fields') }}</h5>
            </div>
            <div class="row col-12 justify-content-center">
              <div class="form-group col-md-8 mt-5 d-flex">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{ $t('export_import.field_in_the_system') }}</th>
                    <th>{{ $t('export_import.corresponding_field_in_the_file') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row , index) in col_list" :key="index">
                    <td>
                      {{ $t('customers.' + row) }}
                    </td>
                    <td>
                      <select name="" id="import_col_list" v-model="import_col_list[row]" class="custom-select">
                        <option v-for="(row , index) in headers_list" :value="row" :key="'import_col_list_'+index">
                          {{ row }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>


          </div>

        </div>
        <div v-show="step == 3" class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-12 mt-5">
              <b-alert v-if="process_message" show :variant="process_finished? 'success' :'warning'">{{ process_message }}</b-alert>
            </div>
            <div class="form-group col-md-6 mt-5">
              <button type="button" class="btn btn-primary mr-2" v-if="process_finished" @click="resetAll">{{ $t('import_again') }}</button>
            </div>
          </div>

        </div>
        <div class="card-footer pl-0 pr-0" v-if="!process_finished">
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-between">
              <button type="button" class="btn btn-primary mr-2" v-if="step > 1" @click="prevStep">{{ $t('previous') }}</button>
              <button type="button" class="btn btn-primary mr-2" v-if="step < 3" @click="nextStep">{{ $t('next') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import Vue from "vue";

export default {
  name: "Index",
  data() {
    return {
      lang: i18nService.getCurrentLanguage(),
      mainRouteDependency: 'base/dependency',
      mainRoute: 'customers/import_customer',
      dir_upload: null,
      reloadUploadAttachment: true,
      file_url: null,
      is_requesting: false,
      errors_list: [],
      specified: ',',
      specified_list: [
        {key: ',', title: '(,) Semicolon Separated'}
      ],
      import_first_row: false,
      file: null,


      innerId: '1',
      placeholder: this.$t('import_data'),
      disableLoadingButton: false,
      btnClass: 'btn btn-primary btn-sm',

      headers_list: [],
      import_col_list: {
        fullname: '',
        phone: '',
        mobile: '',
        email: '',
        country: '',
        city_id: '',
        // status: '',
        customer_code: '',
        currency_id: '',
        // category: '',
        // is_default: '',
        is_active: '',
        branch_id: '',
        customer_type: '',
        commercial_register: '',
        tax_register: '',
      },

      step: 1,
      min_step: 1,
      max_step: 3,
      alter_message: '',
      is_ready_to_next_step: false,
      process_finished: false,
      process_message: this.$t('the_data_import_process_is_in_progress'),
      angle_class: '',
    }
  },
  computed: {
    col_list: function () {
      return Object.keys(this.import_col_list);
    }
  },
  methods: {
    resetAll() {
      this.$refs.fileField.value = null;
      this.file = null;
      this.headers_list = [];
      this.import_col_list = {
        fullname: '',
        phone: '',
        mobile: '',
        email: '',
        country: '',
        city_id: '',
        // status: '',
        customer_code: '',
        currency_id: '',
        // category: '',
        // is_default: '',
        is_active: '',
        branch_id: '',
        customer_type: '',
        commercial_register: '',
        tax_register: '',
      };

      this.step = 1;
      this.alter_message = '';
      this.is_ready_to_next_step = false;
      this.process_finished = false;
      this.process_message = this.$t('the_data_import_process_is_in_progress');

    },
    nextStep() {
      if (this.is_ready_to_next_step) {
        if (this.step < this.max_step)
          this.step = this.step + 1;
        this.sendRequestData()
      } else {
        this.alter_message = this.$t('please_choose_any_file');
      }
    },
    prevStep() {
      if (this.step > this.min_step)
        this.step = this.step - 1;
    },
    listenerAttachment() {

    },
    listenerErrors(errors) {
      this.errors_list = errors ? errors : [];
    },
    sendRequestData() {
      if (this.step == 3) {
        let formData = new FormData();
        formData.append('file', this.file);
        // formData.append('column', this.import_col_list);
        Object.entries(this.import_col_list).forEach(([key, value]) => {
          formData.append('columns[' + key + ']', value);
        });
        formData.append('specified', this.specified);
        formData.append('import_first_row', this.import_first_row ? 1 : 0);
        ApiService.postFormData(this.mainRoute,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then((response) => {
          this.process_message = this.$t('the_data_import_process_is_complete');
          this.process_finished = true;
          this.$successAlert(response.data.message);
          Vue.prototype.$postStatus = true;
        }).catch((error) => {
          this.resetAll();
          this.$errorAlert(error);
          Vue.prototype.$postStatus = true;
        });
      }

    },
    getFileTemplate() {
      this.is_requesting = true;
      ApiService.get(this.mainRouteDependency + "/csv/template/customer").then((response) => {
        this.is_requesting = false;
        if (response.data.data.link)
          window.open(response.data.data.link, '_blank');
      }).catch(() => {
        this.is_requesting = false;
      });
    },
    uploadFile() {
      let files = document.getElementById('inputImportExcel' + this.innerId).files;

      this.alter_message = '';
      this.is_ready_to_next_step = true;
      this.file = null;

      if (files.length == 0) {
        this.alter_message = this.$t('please_choose_any_file');
        this.is_ready_to_next_step = false;
        return;
      }
      let filename = files[0].name;
      let extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
      if (extension == '.CSV') {
        this.file = files[0];
        //Here calling another method to read CSV file into json
        this.csvFileToJSON(files[0]);
      } else {
        this.alter_message = this.$t('please_select_a_valid_csv_file');
        this.is_ready_to_next_step = false;
      }
    },
    csvFileToJSON(file) {
      let that = this;
      try {
        let reader = new FileReader();
        reader.readAsText(file, 'utf-8');
        // reader.readAsBinaryString(file);
        reader.onload = function (e) {
          // let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\r\n");
          // for (let i = 0; i < rows.length; i++) {
          let cells = rows[0].split(that.specified);
          // let rowData = {};
          for (let j = 0; j < cells.length; j++) {
            // if (i == 0) {
            let headerName = cells[j].trim();
            headers.push(headerName);
            // }
            // else {
            //   let key = headers[j];
            //   if (key) {
            //     rowData[key] = cells[j].trim();
            //   }
            // }
          }
          //skip the first row (header) data
          // if (i != 0) {
          //   jsonData.push(rowData);
          // }
          // }

          that.headers_list = headers
          //displaying the json result in string format
          // document.getElementById("display_csv_data").innerHTML = JSON.stringify(jsonData);
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.export_import_customers")}]);
  },
  created() {
    this.angle_class = this.lang == 'ar' ? 'fa fa-angle-left' : 'fa fa-angle-right';
  },
}
</script>

<style scoped>

</style>